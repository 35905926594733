<!-- 28-5-2, 28-6-2, 28-7-2, 28-8-2 -->
<template>
  <rating-detail-item
    :rating="rating"
    :isTextAreaShown="false"
    :viewOnly="rating?.additionalRating?.status === RatingStatus.ACCEPTED"
    :title="$t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_title')"
  >
    <right-wing>
      <right-wing-item
        :rightWingTitle="
          $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_guide')
        "
      >
        <safe-html
          :html="`${$t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_guide1'
          )} <br /> ${$t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_guide2_1'
          )} <br /> ${$t(
            'studio.prj_prod_mngmt.rating_build_review.self_review.rating_adj_guide2_2'
          )}`"
        />
      </right-wing-item>
    </right-wing>
  </rating-detail-item>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchRatingsProductsApi } from '@/apis/rating.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import RatingDetailItem from '@/components/rating/detail/rating-detail-item.vue';
import { RatingStatus } from '@/enums/rating.enum';
import type { RatingProductResponse } from '@/types/rating/rating.response.type';
import { getCorrectRating } from '@/utils/rating.util';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  pageTitle: 'studio.prj_prod_mngmt.rating_build_review.self_review.breadcrumb4_3'
});

const route = useRoute();
const productNo = route.params.productId as string;

const rating = ref<RatingProductResponse>();

const init = async () => {
  const res = await fetchRatingsProductsApi(productNo);
  if (res) {
    rating.value = res;

    const ratingData = getCorrectRating(res.requestRating, res.releasedRatings);

    if (ratingData) {
      rating.value.rating = ratingData;
    }
  }
};

init();
</script>
